// Loader.module.scss
.loaderContainer {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 200px;
}

.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border-left-color: #09f;
	animation: spin 1s ease infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

