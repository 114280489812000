// LandingPage.module.scss
@import '../../styles/media';
@import '../../styles/variables';
@import '../../styles/mixins';

.landingPage {
	max-width: 1440px;
	flex-grow: 1;
	margin: auto; /* Центрируем содержимое */
	padding: 0 15px; /* Добавляем отступы с боков */
	box-sizing: border-box; /* Учитываем padding и border в ширине элемента */
}

.fullWidthSlider {
	width: 100%;
}

.landingPage__link {
	display: inline; /* Убираем inline-block, чтобы ссылка не нарушала общий поток текста */
	line-height: inherit; /* Наследуем единое значение line-height */
	color: var(--color-dark-blue);
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.titleBlock{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin: 0 auto;
	padding: 90px 70px 0 360px;
	max-width: 1440px;

	@include desktop {
		padding: 90px 0 0 100px;
	}

	@include tablet {
		padding: 90px 0 0 90px;
	}

	@include mobile {
		padding: 80px 0 0 20px;
	}
}

.titleBlock__textTitle {
	margin: 20px 90px 10px 10px;
	font-size: 90px;

	@include desktop {
		font-size: 90px;
	}

	@include tablet {
		margin: 20px 30px 10px 10px;
		font-size: 70px;
	}

	@include mobile {
		margin: 10px 0 0 0;
		font-size: 50px;
	}

	@include mobile-small {
		font-size: 40px;
	}
}

.titleBlock__textCopyright {
	margin: 120px 10px 10px 10px;
	font-size: 20px;

	@include desktop {
		margin: 90px 0 0 0;
		font-size: 20px;
	}

	@include tablet {
		font-size: 16px;
	}

	@include mobile {
		font-size: 14px;
	}

	@include mobile-small {

	}
}

.mainLeaf01 {
	position: absolute;
	opacity: 0.1;
	rotate: (300deg);
	width: 300px;
	height: auto;
	top: -120px;
	left: 300px;

	@include desktop {
		top: -90px;
		width: 400px;
		left: 150px;
	}

	@include tablet {
		display: none;
	}

	@include mobile {
		display: none;
	}

	@include mobile-small {
		width: 100px;
	}
}

.mainLeaf02 {
	position: absolute;
	opacity: 0.1;
	rotate: (300deg);
	width: 300px;
	height: auto;
	top: 2000px;
	left: 250px;

	@include tablet {
		top: 800px;
		width: 250px;
		left: 250px;
	}

	@include mobile {
		top: 700px;
		width: 2500px;
		left: 200px;
	}

	@include mobile-small {
		width: 100px;
	}
}

/* Удаляем индивидуальные padding, которые могут нарушить центрирование */
.landingPage__ecoprint,
.landingPage__process,
.landingPage__plants,
.landingPage__lets_magic,
.landingPage__bye {
	padding: 14px 60px 0 60px;

	@include desktop {
		padding: 14px 40px 0 40px;
	}

	@include tablet {
		padding: 14px 20px 0 20px;
	}

	@include mobile {
		padding: 14px 10px 0 10px;
	}

	@include mobile-small {
		padding: 14px 5px 0 5px;
	}
}

.landingPage__video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 aspect ratio */
	padding-top: 60px;
	height: 0;
	overflow: hidden;
}

.landingPage__video video {
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.landingPage__button {
	@include button-style;

	@include tablet {
		font-size: 1.3rem;
		width: 100%;
	}

	@include mobile {
		font-size: 1.3rem;
		width: 100%;
	}

	@include mobile-small {
		font-size: 1.3rem;
		width: 100%;
	}
}

.landingPage__link {
	display: inline-block;
	margin-top: 10px;
	color: var(--color-dark-blue);
	text-decoration: none;
	line-height: inherit;

	&:hover {
		text-decoration: underline;
	}
}