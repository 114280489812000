@import '../../styles/media';
@import '../../styles/variables';
@import '../../styles/mixins';

.masterclass {
	max-width: 1440px;
	padding: 110px 70px 30px 70px;
	margin: 0 auto;
	text-align: left;

	@include tablet {
		padding: 110px 30px 20px 30px;
	}

	@include mobile {
		padding: 110px 20px 20px 20px;
	}

	@include mobile-small {
		padding: 110px 10px 20px 10px;
	}

	//a {
	//	margin-left: 30px;
	//
	//	@include tablet {
	//		margin-left: 20px;
	//	}
	//
	//	@include mobile {
	//		margin-left: 10px;
	//	}
	//
	//	@include mobile-small {
	//		margin-left: 5px;
	//	}
	//}

	h2 {
		margin-bottom: 2rem;
		text-align: center;

		@include tablet {
			margin-bottom: 1rem;
		}

		@include mobile {
			margin-bottom: 1rem;
		}

		@include mobile-small {
			margin-bottom: 1rem;
		}

	}

	p {
		margin-bottom: 1rem;
		line-height: 1.5;

		@include tablet {
			margin-bottom: 0.5rem;
		}

		@include mobile {
			margin-bottom: 0.5rem;
		}

		@include mobile-small {
			margin-bottom: 0.5rem;
		}

	}

	.chapters {
		width: 100%;
		padding: 1rem 2rem;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
		justify-content: center;
		align-items: center;

		@include tablet {
			grid-template-columns: 1fr;
			padding: 1rem 0.5rem;
		}

		@include mobile {
			grid-template-columns: 1fr;
			padding: 0.8rem 0.5rem;
		}

		@include mobile-small {
			grid-template-columns: 1fr;
			padding: 1rem 0.5rem;
		}
	}

	.chapter {
		width: 570px;
		display: flex;
		flex-direction: column;
		text-align: left;
		color: var(--color-black);
		border-radius: 5px;
		background: var(--color-light-green);
		box-shadow: var(--box-shadow);
		padding: 0.7rem;
		text-decoration: none;
		cursor: pointer;
		position: relative;
		margin: 0 auto;

		@include desktop {
			width: 450px;
			padding: 0.5rem;
		}

		@include tablet {
			width: 680px;
			padding: 0.5rem;
		}

		@include mobile {
			width: 450px;
			padding: 0.5rem;
		}

		@include mobile-small {
			width: 300px;
		}

		.chapterNumber {
			position: absolute;
			left: -20px;
			top: 50%;
			transform: translateY(-50%);
			-webkit-backdrop-filter: blur(10px);
			backdrop-filter: blur(10px);
			width: 55px;
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			font-family: LetteraTrentadue, sans-serif;
			font-size: 2rem;
			color: var(--color-red);
			box-shadow: var(--box-shadow);

			@include tablet {
				width: 50px;
				height: 50px;
				font-size: 1.8rem;
			}

			@include mobile {
				width: 40px;
				height: 40px;
				font-size: 1.5rem;
			}

			@include mobile-small {
				width: 30px;
				height: 30px;
				font-size: 1rem;
			}
		}
	}


	.chapterContent {
		margin-left: 60px;
		padding: 0 8px 0 8px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: left;

		@include tablet {
			margin-left: 20px;
		}

		@include mobile {
			margin-left: 10px;
		}

		@include mobile-small {
			margin-left: 10px;
		}

		h3 {
			font-size: 1.5rem;
			margin-bottom: 1rem;

			@include tablet {
				font-size: 1.3rem;
				margin-bottom: 0.7rem;
			}

			@include mobile {
				font-size: 1.1rem;
				margin-bottom: 0.7rem;
			}

			@include mobile-small {
				font-size: 1rem;
			}
		}
	}

	&__images {
		margin-bottom: 10px;

	}

	&__videoContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 10px 30px 10px;
		max-width: 1000px;
		margin: 0 auto;

		video {
			width: 100%;
			height: auto;
		}
	}
}

.telegramButtonContainer {
	text-align: center;
	margin-top: 30px;
	padding: 20px;
	background-color: var(--color-main);
}

.telegramButton {
	@include button-style;
	padding: 10px 20px;
	background-color: var(--color-blue);
	color: var(--color-main);
	margin: 0 auto;
	display: block;
	font-size: 1.4rem;
	width: 450px;


	@include tablet {
		font-size: 1.3rem;
		width: 100%;
	}

	@include mobile {
		font-size: 1.1rem;
		width: 100%;
	}

	@include mobile-small {
		font-size: 1.1rem;
		width: 100%;
	}
}

.faqSection {
	margin-top: 40px;
	padding: 20px;
	background-color: var(--color-main);

}

.faqItem {
	margin-bottom: 10px;
	padding: 5px;
	border-bottom: 1px solid #ccc;

	p {
		font-size: 1.2rem;
		margin: 0;
	}
}

