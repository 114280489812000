// MarenGardenContent.module.scss
@import '../../../styles/media';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.content {
	display: flex;
	max-width: 1440px;
	padding: 110px 70px 0 70px;
	margin: 0 auto;
	text-align: left;

	@include tablet {
		flex-direction: column;
		padding: 60px 0 0 0;
	}

	@include mobile {
		flex-direction: column;
		padding: 60px 0 0 0;
	}

	@include mobile-small {
		flex-direction: column;
		padding: 30px 0 0 0;
	}
}


.sidebar {
	width: 40%;
	padding: 30px;

	@include tablet {
		display: none;
	}

	@include mobile {
		display: none;
	}

	@include mobile-small {
		display: none;
	}

	ol {
		font-family: LetteraTrentadue, sans-serif;
		font-size: 1.3rem;
		list-style: decimal outside;
		padding: 0;
		margin-bottom: 0;

		li {
			cursor: pointer;
			padding: 0 10px;
			font-size: 1.3rem;
		}
	}

	.activeChapter {
		color: var(--color-red);
		font-weight: bold;
	}
}

.chapterContent {
	width: 65%;
	padding: 10px 10px 0 10px;

	@include tablet {
		width: 100%;
	}

	@include mobile {
		width: 100%;
	}

	@include mobile-small {
		width: 100%;
	}

	h3 {
		margin-bottom: 20px;
	}
}

.contentContainer {
	display: flex;
	align-items: flex-start; /* Выравнивание по верхнему краю */
	gap: 20px; /* Расстояние между текстом и изображением */
}

.textContent {
	flex: 1; /* Занимает оставшееся пространство */
}

.imageContent {
	flex: 0 0 auto; /* Фиксированная ширина */
	max-width: 300px;
	margin-top: 40px;
}

.imageContent img {
	width: 100%;
	height: auto; /* Сохранение пропорций изображения */
}

.backButton {
	@include button-style;
	display: block;
	font-size: 1.4rem;
	width: 100%;


	@include tablet {
		font-size: 1.3rem;
		width: 100%;
	}

	@include mobile {
		font-size: 1.1rem;
		width: 100%;
	}

	@include mobile-small {
		font-size: 1.1rem;
		width: 100%;
	}
}

