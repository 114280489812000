@import '../../styles/media';
@import '../../styles/variables';

.changePassword {
	width: 100%;
	padding: 200px 0 50px 0;
	max-width: 1440px;
	flex-grow: 1;
	display: flex;
	justify-content: center;
}

.changePasswordContainer {
	width: 480px;
	height: auto;
}

.formGroup {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.passwordWrapper {
	position: relative;
	width: 100%;

	input {
		width: 100%;
		height: 35px;
		padding: 8px;
		border: 1px solid #c7c7c7;
		border-radius: 5px;
		box-shadow: 0 0 10px 0 #00000011;

		&:focus {
			outline: 1px solid var(--color-green);
			border-color: var(--color-green);
			font-size: 16px;
			padding-left: 20px;
		}
	}

	.passwordToggle {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		color: var(--color-grey);
	}
}

.changePasswordFormWrapper {
	width: 429px;
	height: auto;
	border: 0;
	border-radius: 10px;
	box-shadow: 0 0 10px 0 #00000033;
	margin-top: -40px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h2 {
		font-size: 24px;
		margin-bottom: 20px;
	}

	form {
		width: 100%;
		font-size: 16px;

		div {
			margin-bottom: 15px;

			label {
				display: block;
				margin-bottom: 5px;
				color: var(--color-grey);
			}
		}

		button {
			width: 369px;
			margin-top: 20px;
			text-align: center;
			padding: 10px;
			background-color: var(--color-green);
			color: var(--color-main);
			border: none;
			box-shadow: 0 0 20px 0 #00000033;
			border-radius: 5px;
			cursor: pointer;
		}
	}

	.error {
		margin-bottom: 15px;
		color: var(--color-red);
		font-weight: bold;
	}

	.success {
		margin-bottom: 15px;
		color: var(--color-dark-green);
		font-weight: bold;
	}
}