// LessonsPage.module.scss
@import '../../styles/media';
@import '../../styles/variables';

.lessons {
	max-width: 1440px;
	padding: 110px 70px 0 70px;
	margin: 0 auto;
	text-align: center;

	@include tablet {
		padding: 110px 20px 0 20px;
	}

	@include mobile {
		padding: 110px 10px 0 10px;
	}

	@include mobile-small {
		padding: 110px 5px 0 5px;
	}

	&__videoContainer {
		padding: 20px 60px 30px 60px;
		max-width: 00px;
		video {
			width: 100%;
			height: auto;
		}
	}

	h1 {
		margin-bottom: 2rem;

		@include tablet {
			text-align: center;
			font-size: 2.1rem;
		}

		@include mobile {
			font-size: 2rem;
		}

		@include mobile-small {
			font-size: 1.7rem;
		}
	}

	&__card {
		width: 100%;
		padding: 1rem 2rem;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 2rem;
		justify-content: center;
		align-items: center;


		@include mobile {
			grid-template-columns: 1fr;
		}

		@include mobile-small {
			grid-template-columns: 1fr;
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		text-align: center;
		color: var(--color-black);
		border-radius: 5px;
		background: var(--color-main);
		box-shadow: var(--box-shadow);
		padding: 1rem;
		text-decoration: none;


		@include tablet {
			width: 100%;
		}

		@include mobile {
			width: 100%;
		}

		&_title {
			font-size: 1.8rem;
			margin-bottom: 1rem;

			@include tablet {
				font-size: 1.5rem;
			}

			@include mobile {
				font-size: 1.2rem;
			}

			@include mobile-small {
				font-size: 1rem;
			}
		}

		&_image {
			position: relative;
			display: block;
			width: 100%;
			padding-top: 70%;
			background: var(--color-main);
			cursor: pointer;

			@include tablet {
				padding-top: 100%;
			}

			@include mobile {
				padding-top: 100%;
			}

			@include mobile-small {
				padding-top: 100%;
			}

			img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}
