// Modal.module.scss
@import '../../styles/media';
@import '../../styles/variables';
@import '../../styles/mixins';


.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	background: var(--color-main);
	padding: 20px;
	border-radius: 15px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalContent {
	text-align: center;
}

.modal__button {
	@include button-style;
}