@import '../../../styles/media.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.userBlock {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-width: 280px;

	@include desktop {
		min-width: 200px;
	}

	@include tablet {
		min-width: 0;
	}

	@include mobile {
		min-width: 0;
	}
}

.userContainer {
	display: flex;
	align-items: center;
	gap: 16px;
}

.userInfo {
	display: flex;
	justify-content: flex-end;
	flex: 0 0 auto;
	//min-width: 100px;
	height: 32px;
	align-items: center;

	@include desktop {
		height: 24px;
	}

	@include tablet {
		justify-content: center;
	}

	@include mobile {
		justify-content: center;
	}

	@include mobile-small {
		justify-content: center;
	}
}

.userInfo__details {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 2px;
	width: 100%;
}

.userInfo__userName {
	opacity: 0.7;
	font-size: 18px;
	margin-right: 8px;

	@include desktop {
		font-size: 15px;
	}

	@include tablet {
		font-size: 14px;
	}

	@include mobile {
		font-size: 16px;
	}
}

.userInfo__userIcon {
	width: 40px;
	height: 40px;
	opacity: 0.7;
	border-radius: 50%;
	animation: spin 10s linear infinite;

	@include tablet {
		width: 30px;
		height: 30px;
	}

	@include mobile {
		width: 40px;
		height: 40px;
	}
}

.cartContainer {
	margin-left: 8px;

	@include tablet {
		margin-left: 0;
	}

	@include mobile {
		margin-left: 0;
	}

}

.loginButton {
	@include button-header;

	@include mobile {
		display: none;
	}
}