@import '../../styles/media';
@import '../../styles/variables';

.resetPassword {
	width: 100%;
	padding: 120px 0 30px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border: 1px solid #ccc;
	border-radius: 8px;
	background-color: var(--color-light-main);
	text-align: center;
}


input {
	width: 300px;
	padding: 10px;
	margin: 10px 20px 10px 0;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.button_reset {
	flex: 1;
	padding: 10px;
	background-color: var(--color-red);
	color: #fff;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	margin: 0 5px;

	&:hover {
		background-color: var(--color-blue);
	}

	&:disabled {
		background-color: var(--color-light-grey);
		cursor: not-allowed;
	}

	@include mobile {
		width: 100%;
		margin: 0;
	}
}

.message {
	margin-top: 10px;
	color: red;
}