@import '../../../styles/media';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.cartButton {
	display: flex;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
	position: relative;

	img {
		width: 30px;
		height: 30px;
	}

	.cartCount {
		position: absolute;
		top: -5px;
		right: -5px;
		background: var(--color-red);
		color: var(--color-main);
		border-radius: 50%;
		padding: 2px 5px;
		font-size: 12px;
	}
}
