// SlideList.module.scss
.slideList {
	display: flex;
	transition: transform 0.5s ease-in-out;
}

.slide {
	min-width: 100%;
	box-sizing: border-box;
	text-align: center;
}

.slideImage {
	width: 100%;
	height: auto;
}
