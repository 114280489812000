@import '../../../styles/media';

.navbar {
	display: flex;
	flex: 0 0 750px;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	text-transform: uppercase;
	justify-content: space-between;
	align-items: center;

	@include desktop {
		flex: 0 0 680px;
		font-size: 16px;
	}

	@include tablet {
		flex: 0 0 490px;
		font-size: 12px;
	}

	@include mobile {
		flex-direction: column;
		align-items: flex-start;
		flex: 0 0 230px;
		font-size: 16px;
	}

	@include mobile-small {
		flex-direction: column;
		align-items: flex-start;
		flex: 0 0 230px;
		font-size: 12px;
	}
}

.navbar a {
	color: var(--color-black);
	text-decoration: none;
	margin-right: 20px;
	transition: opacity 0.3s ease;

	@include desktop {
		margin-right: 10px;
	}

	@include tablet {
		margin-right: 10px;
	}

	@include mobile {
		margin-right: 0;
		margin-bottom: 10px;
		font-size: 20px;
	}

	@include mobile-small {
		font-size: 20px;
	}
}

.navbar a:hover {
	opacity: 0.5;
}
