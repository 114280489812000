// PromoPage.module.scss
@import '../../../styles/media';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.promo {
	max-width: 1440px;
	padding: 110px 70px 0 70px;
	margin: 0 auto;
	text-align: left;

	@include desktop {
		padding: 110px 20px 0 20px;
	}

	@include tablet {
		padding: 110px 10px 0 10px;
	}

	@include mobile {
		padding: 160px 5px 0 5px;
	}

	@include mobile-small {
		padding: 160px 5px 0 5px;
	}

	h1 {
		margin-bottom: 2rem;
		text-align: center;
	}

	p {
		margin-bottom: 1rem;
		line-height: 1.3; /* Высота строки */
	}

	ul {
		margin-bottom: 1rem; /* Отступ между списком и другими элементами */
		padding-left: 20px; /* Отступ слева для списка */
		line-height: 1.3; /* Высота строки */
	}

	ol {
		margin-bottom: 1.5rem; // Отступ внизу списка
		padding-left: 10px;
		font-size: 1rem;
		line-height: 1.2; // Высота строки как над списком

		li {
			margin-bottom: 0.3rem; // Отступ между элементами списка
			list-style-position: inside;
		}
	}

	.buyButton {
		@include button-style;

		@include tablet {
			font-size: 1.3rem;
			width: 100%;
		}

		@include mobile {
			font-size: 1.3rem;
			width: 100%;
		}

		@include mobile-small {
			font-size: 1.3rem;
			width: 100%;
		}
	}

	.gallery {
		margin-top: 20px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.galleryImage {
			width: 100%;
			height: auto;
			max-height: 700px; /* Ограничение максимальной высоты */
		}
	}
}

.notification {
	position: fixed;
	top: 200px;
	left: 30%;
	transform: translateX(-50%);
	background-color: var(--color-red);
	opacity: 70%;
	color: var(--color-main);
	padding: 10px 20px;
	border-radius: 5px;
	font-family: 'LetteraTrentadue', sans-serif;
	font-size: 30px;
	z-index: 1000;
	rotate: -20deg;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	@include mobile {
		left: 50%;
	}

	@include mobile-small {
		left: 50%;
	}

	@include tablet {
		left: 40%;
	}
}