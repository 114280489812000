@import '../../../styles/media';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.cartPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 1440px;
	padding: 110px 70px 20px 70px;
	margin: 0 auto;

	@include desktop {
		padding: 110px 20px 20px 20px;
	}

	@include tablet {
		padding: 110px 10px 20px 10px;
	}

	@include mobile {
		padding: 160px 5px 20px 5px;
	}

	h1 {
		margin-bottom: 20px;
		font-size: 2rem;
		color: var(--color-black);
	}

	.summaryLeft {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.cartContainer {
		display: flex;
		flex-direction: row-reverse;
		gap: 20px;
		width: 100%;
		max-width: 1200px;

		@include tablet {
			flex-direction: column;
		}

		@include mobile {
			flex-direction: column;
		}

		@include mobile-small {
			flex-direction: column;
		}
	}

	.cartSummary {
		display: flex;
		flex-direction: column;
		border: 1px solid #eee;
		box-shadow: var(--box-shadow);
		padding: 20px;
		border-radius: 5px;
		background-color: var(--color-light-main);
		margin-bottom: 20px;
		width: 450px;
		max-width: 500px;

		@include tablet {
			width: 100%;
			max-width: none;
		}

		@include mobile {
			width: 100%;
			max-width: none;
		}

		.totalPrice, .totalItems {
			font-size: 1.8rem;
			font-family: Iosevka, sans-serif;
			margin-bottom: 10px;

			@include tablet {
				font-size: 1.5rem;
			}

			@include mobile {
				font-size: 1.3rem;
			}

			@include mobile-small {
				font-size: 1.2rem;
			}
		}

		.summaryRight {

			.checkoutButton {
				@include button-style;
				margin: 0 auto 20px;
				display: block;
				font-size: 1.4rem;
				width: 100%;
				transition: background-color 0.3s;

				&:hover {
					background-color: var(--color-blue);
				}

				&:disabled {
					background-color: var(--color-light-grey);
					cursor: not-allowed;
				}

				@include tablet {
					font-size: 1.3rem;
					width: 100%;
				}

				@include mobile {
					font-size: 1.1rem;
					width: 100%;
				}

				@include mobile-small {
					font-size: 1.1rem;
					width: 100%;
				}
			}
		}

		//.agreement {
		//	margin-top: 10px;
		//	font-size: 0.8rem;
		//
		//	label {
		//		display: flex;
		//		align-items: center;
		//		gap: 5px;
		//		cursor: pointer;
		//	}
		//
		//	input[type="checkbox"] {
		//		width: 50px;
		//		margin-right: 5px;
		//		margin-top: 0;
		//	}
		//
		//	span {
		//		font-size: 1.1rem;
		//		line-height: 1.2;
		//
		//		@include tablet {
		//			font-size: 1rem;
		//		}
		//
		//		@include mobile {
		//			font-size: 1rem;
		//		}
		//
		//		@include mobile-small {
		//			font-size: 0.9rem;
		//		}
		//	}
		//}

		.agreement {
			margin-top: 15px;
			width: 100%;

			.agreementLabel {
				display: flex;
				align-items: flex-start;
				gap: 12px;
				cursor: pointer;
				width: 100%;
			}

			.checkboxWrapper {
				position: relative;
				display: flex;
				align-items: center;
			}

			// Скрытый оригинальный чекбокс
			.hiddenCheckbox {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				width: 0;
				height: 0;
			}

			// Кастомный чекбокс
			.customCheckbox {
				width: 20px;
				height: 20px;
				background: white;
				border: 2px solid var(--color-blue);
				border-radius: 4px;
				display: inline-block;
				position: relative;
				cursor: pointer;

				// Галочка
				&:after {
					content: '';
					position: absolute;
					display: none;
					left: 6px;
					top: 2px;
					width: 5px;
					height: 10px;
					border: solid white;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}

			// Когда чекбокс отмечен
			.hiddenCheckbox:checked + .customCheckbox {
				background-color: var(--color-blue);

				&:after {
					display: block;
				}
			}

			.agreementText {
				font-family: 'Inter', sans-serif;
				font-size: 1.2rem;
				line-height: 1.4;
				color: var(--color-grey);
				flex: 1;

				@include tablet {
					font-size: 1.1rem;
				}

				@include mobile {
					font-size: 1rem;
				}
			}

			.offerLink {
				color: var(--color-blue);
				text-decoration: none;
				font-weight: 500;
				border-bottom: 1px solid transparent;
				transition: border-color 0.3s ease;

				&:hover {
					border-bottom-color: var(--color-blue);
				}
			}
		}

		.offerLink {
			color: var(--color-blue);
			text-decoration: underline;
			font-weight: bold;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.cartItems {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;

		@include tablet {
			order: 2;
		}

		@include mobile {
			order: 2;
		}

		@include mobile-small {
			order: 2;
		}
	}

	.cartItem {
		display: flex;
		align-items: center;
		color: var(--color-black);
		border-radius: 10px;
		background: var(--color-light-main);
		box-shadow: var(--box-shadow);
		padding: 20px;
		position: relative;

		.cartItemImage {
			width: 200px;
			height: auto;
			border-radius: 10px;
			margin-right: 20px;

			@include tablet {
				width: 150px;
			}

			@include mobile {
				width: 120px;
			}

			@include mobile-small {
				width: 80px;
			}

			img {
				width: 100%;
				height: auto;
				border-radius: 5px;
			}
		}


		.cartItemDetails {
			flex: 1;
			display: flex;
			flex-direction: column;

			h2 {
				font-size: 1.5rem;
				margin-bottom: 10px;

				@include tablet {
					font-size: 1.3rem;
				}

				@include mobile {
					font-size: 0.9rem;
				}

				@include mobile-small {
					font-size: 0.9rem;
				}
			}

			.cartItemPrice {
				font-size: 1.2rem;
				font-weight: bold;
				margin-left: auto;
				text-align: right;

				p {
					margin-bottom: 10px;
				}

				.removeButton {
					@include button-style;
					margin: 0 auto 10px;
					display: block;
					font-size: 1.4rem;
					width: 300px;


					@include tablet {
						font-size: 1.3rem;
						width: 300px;
					}

					@include mobile {
						font-size: 0.9rem;
						width: 150px;
					}

					@include mobile-small {
						font-size: 0.9rem;
						width: 120px;
					}
				}
			}
		}
	}

	.emptyCart {
		text-align: center;
		padding: 2rem;
		background-color: var(--color-light-main);
		border-radius: 10px;
		box-shadow: var(--box-shadow);
		width: 100%;
		max-width: 600px;

		h1, h3, p {
			font-size: 2rem;
			margin-bottom: 10px;

			@include tablet {
				font-size: 1.7rem;
			}

			@include mobile {
				font-size: 1.4rem;
			}

			@include mobile-small {
				font-size: 1.4rem;
			}
		}

		.removing {
			opacity: 0.5;
			cursor: not-allowed;
		}

		.loadingSpinner {
			border: 3px solid #f3f3f3;
			border-top: 3px solid #3498db;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			animation: spin 1s linear infinite;
			margin: 0 auto;
		}

		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}

		.continueShopping {
			@include button-style;
			margin: 0 auto 20px;
			display: block;
			font-size: 1.4rem;
			width: 100%;

			@include tablet {
				font-size: 1.3rem;
				width: 100%;
			}

			@include mobile {
				font-size: 1.1rem;
				width: 100%;
			}

			@include mobile-small {
				font-size: 1.1rem;
				width: 100%;
			}
		}
	}

	.backButton {
		@include button-style;
		margin: 10px auto 10px;
		display: block;
		font-size: 1.4rem;
		width: 500px;


		@include tablet {
			font-size: 1.3rem;
			width: 500px;
		}

		@include mobile {
			font-size: 1.1rem;
			width: 100%;
		}

		@include mobile-small {
			font-size: 1.1rem;
			width: 100%;
		}

		.privacyPolicyLink {
			margin-top: 10px;
			font-size: 1.2rem;
			color: var(--color-red);
			text-decoration: underline;
			cursor: pointer;
		}
	}
}