// ShopPage.module.scss
@import '../../styles/media';
@import '../../styles/variables';
@import '../../styles/mixins';

.shopContainer {
	max-width: 1440px;
	display: flex;
	padding: 110px 70px 20px 70px;
	margin: 0 auto;

	@include desktop {
		padding: 110px 20px 20px 20px;
	}

	@include tablet {
		padding: 110px 10px 20px 10px;
		flex-direction: row;
	}

	@include mobile {
		padding: 160px 5px 20px 5px;
		flex-direction: column;
	}

	@include mobile-small {
		padding: 110px 5px 20px 5px;
	}
}

.categoriesWrapper {
	position: -webkit-sticky; /* Для поддержки Safari */
	position: sticky;
	top: 100px;
	align-self: flex-start;

	&.hidden {
		display: none;
	}

	@include mobile {
		display: none;
	}
}

.categoriesContainer {
	flex: 1;
	font-family: LetteraTrentadue, sans-serif;
	padding-right: 20px;

	@include tablet {
		padding-right: 10px;
	}

	@include mobile {
		padding-right: 5px;
		font-size: 1.3rem;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 1.8rem;

		@include tablet {
			font-size: 1.5rem;
		}

		@include mobile {
			font-size: 1.3rem;
		}
	}

	li {
		margin-bottom: 10px;

		button {
			background: none;
			border: none;
			cursor: pointer;
			font-size: inherit;
			color: inherit;
			padding: 0;
			margin: 0;

			&:hover {
				color: var(--color-blue);
			}
		}

		.activeCategory {
			color: var(--color-blue);
		}
	}
}

.productsContainer {
	flex: 3;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		margin-bottom: 20px;
	}
}

.productsGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 20px;
	width: 100%;

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mobile {
		grid-template-columns: 1fr;
	}
}

.productCard {
	background: var(--color-light-main);
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	text-align: left;
	transition: transform 0.3s ease;
	padding: 20px;

	&:hover {
		transform: translateY(-10px);
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	@include mobile {
		max-width: 100%;
		margin: 20px auto 20px;
	}
}

.mobileMenu {
	display: none;
	font-family: LetteraTrentadue, sans-serif;
	font-size: 2rem;

	@include mobile {
		display: block;
		position: fixed;
		top: 70px;
		left: 0;
		right: 0;
		z-index: 1000;
		padding: 10px;
		background-color: var(--color-light-main);
	}
}

.categoryButton {
	width: 100%;
	padding: 7px 16px;
	background-color: var(--color-white);
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 24px;
	color: var(--color-black);
}

.arrow {
	font-size: 14px;
	transition: transform 0.3s ease;

	&.arrowUp {
		transform: rotate(180deg);
	}
}

.categoryDropdown {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--color-main);
	opacity: 0.9;
	z-index: 1001;
	display: flex;
	flex-direction: column;
}

.dropdownHeader {
	padding: 16px;
	border-bottom: 1px solid #e0e0e0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	span {
		font-size: 2rem;
		font-weight: 500;
	}

	.closeButton {
		background: none;
		border: none;
		font-size: 24px;
		color: var(--color-black);
		cursor: pointer;
		padding: 4px;
	}
}

.categoryList {
	flex: 1;
	overflow-y: auto;
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		button {
			width: 100%;
			padding: 5px 13px;
			text-align: left;
			background: none;
			border: none;
			font-size: 1.3rem;
			color: var(--color-black);

			&.activeCategory {
				color: var(--color-blue);
			}

			&:active {
				background-color: var(--color-light-main);
			}
		}
	}
}

.doneButton {
	@include button-style;
	background-color: var(--color-blue);
	color: var(--color-white);
	margin: 0;
	border-radius: 0;
	font-size: 1.3rem;
	width: 100%;

	&:hover {
		background-color: var(--color-red);
	}

	@include tablet {
		font-size: 1.1rem;
		width: 100%;
	}

	@include mobile {
		font-size: 1.3rem;
		width: 100%;
	}

	@include mobile-small {
		font-size: 1.3rem;
		width: 100%;
	}
}

.productImage {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
}

.productTitle {
	font-size: 1.5rem;
	margin: 10px 0;
}

.productDescription {
	font-size: 1rem;
	margin: 10px 0;
}

.productNot {
	font-size: 2.5rem;
	font-family: LetteraTrentadue, sans-serif;
	margin: 50px 0 0 30px;
	color: var(--color-red);
}

.productPrice {
	font-size: 1.5rem;
	font-family: Iosevka, sans-serif;
	margin: 10px 0;
	color: var(--color-dark-blue);
}

.productButton {
	@include button-style;
	margin: 0;
	font-size: 1.5rem;
	width: 270px;

	&:hover {
		background-color: var(--color-blue);
	}

	@include mobile {
		width: 100%;
	}
}

.categoriesWrapper {
	@include mobile {
		display: none;
	}
}

