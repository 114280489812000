// mixins.scss
@import './variables';
@import './media';

@mixin button-style {
	display: block;
	width: 220px;
	padding: 10px 20px;
	margin: 20px auto;
	font-family: 'LetteraTrentadue', sans-serif;
	font-size: 2rem;
	text-align: center;
	background-color: var(--color-red);
	color: var(--color-main);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

	&:hover {
		background-color: var(--color-blue);
	}
}


@mixin button-header {
	width: 75px;
	height: 30px;
	border-radius: 3px;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
	margin: 20px auto;
	color: var(--color-main);
	background-color: var(--color-blue);
	font-family: 'Montserrat', sans-serif;
	font-size: 1.1rem;
	text-align: center;

	&:hover {
		cursor: pointer;
		background-color: var(--color-red);
		color: var(--color-main);
	}
}



