// media.scss
$desktop-above: 1281px;
$desktop: 1280px;
$tablet-above: 1024px;
$tablet: 1023px;
$mobile-above: 768px;
$mobile: 767px;
$mobile-small-above: 480px;
$mobile-small: 479px;

@mixin desktop {
	@media (max-width: $desktop) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: $tablet) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $mobile) {
		@content;
	}
}

@mixin mobile-small {
	@media (max-width: $mobile-small) {
		@content;
	}
}

@mixin mobile-above {
	@media (min-width: $mobile-above) {
		@content;
	}
}