.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	background: none;
	border: none;
	color: white;
	font-size: 2rem;
	cursor: pointer;
	z-index: 1001; /* Убедимся, что кнопка закрытия находится выше других элементов */
}

.swiper {
	width: 100%;
	height: 100%;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
