@import '../../styles/media';
@import '../../styles/variables';

.aboutPage {
	max-width: 1440px;
	padding: 100px 100px 20px 90px;
	margin: 0 auto;

	@include desktop {
		padding: 100px 20px 20px 20px;
	}

	@include tablet {
		padding: 100px 10px 20px 10px;
	}

	@include mobile {
		padding: 100px 10px 20px 5px;
	}

	@include mobile-small {
		padding: 100px 5px 20px 5px;
	}
}

.aboutPage__content {
	max-width: 1440px;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr 350px;
	grid-template-rows: auto 1fr;
	gap: 20px;

	@include tablet {
		grid-template-columns: 1fr 250px;
		grid-template-rows: auto auto;
		gap: 10px;
	}

	@include mobile {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		gap: 10px;
	}

	@include mobile-small {
		gap: 10px;
	}
}

.aboutPage__left {
	grid-row: 1 / span 2;

	@include desktop {
		grid-row: 1 / span 1;
	}

	@include tablet {
		grid-row: 1 / span 1;
	}

	@include mobile {
		grid-row: 2 / span 1;
	}
}

.aboutPage__photo {
	grid-row: 1 / 2;
	justify-self: center;

	img {
		max-width: 100%;
		height: auto;
		border-radius: 10px;
		background: center / cover;

		@include tablet {
			max-width: 100%;
		}

		@include mobile {
			max-width: 100%;
		}

		@include mobile-small {
			border-radius: 0;
		}
	}
}

.post {
	display: block;
	width: 100%;
	grid-column: 1 / -1;

	@include tablet {
		grid-column: 1 / -1;
	}

	@include mobile {
		grid-column: 1 / -1;
	}
}
