@import '../../styles/media';
@import '../../styles/variables';

.profile {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	max-width: 1440px;
	padding: 110px 70px 0 70px;
	margin: 0 auto;
	text-align: center;

	@include tablet {
		padding: 110px 30px 0 30px;
	}

	@include mobile {
		padding: 110px 10px 0 10px;
	}
}

.cardMasterClasses {
	background-color: var(--color-light-green);
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
	margin: 20px;
	flex: 1 1 300px;
	max-width: 300px;
	text-align: left;

	h3 {
		margin-bottom: 20px;

		@include tablet {
			font-size: 1.5rem;
		}

		@include mobile {
			font-size: 1.3rem;
		}

		@include mobile-small {
			font-size: 1.1rem;
		}
	}

	@include tablet {
		max-width: 500px;
		margin: 20px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		padding: 20px;
	}

	@include mobile {
		max-width: 500px;
	}
}

.masterclass {
	font-size: 2rem;
	font-family: LetteraTrentadue, sans-serif;
	margin-bottom: 20px;
	color: var(--color-blue);

	@include tablet {
		font-size: 1.5rem;
	}

	@include mobile {
		font-size: 1.5rem;
	}
}

.cardProfile {
	background-color: var(--color-light-grey);
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
	margin: 20px;
	flex: 1 1 500px;
	max-width: 500px;
	text-align: left;

	h3 {
		margin-bottom: 20px;
	}

	.profileInfo {
		position: relative;
		padding-top: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		@include tablet {
			flex-direction: column;
			margin-bottom: 30px;
		}

		@include mobile {
			flex-direction: column;
			margin-bottom: 20px;
		}

		.userIcon {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			position: absolute;
			top: -50px;
			right: 20px;

			@include tablet {
				width: 90px;
				height: 90px;
			}

			@include mobile {
				width: 90px;
				height: 90px;
				right: -5px;
			}
		}

		.userName {
			font-size: 2rem;
			font-family: Iosevka, sans-serif;


			@include tablet {
				font-size: 1rem;
			}

			@include mobile {
				font-size: 0.6rem;
			}

			@include mobile-small {
				font-size: 0.6rem;
			}
		}

		.userInfo {
			text-align: left;

			p {
				margin: 0;
				font-size: 1.3rem;

				@include tablet {
					font-size: 1.5rem;
				}

				@include mobile {
					font-size: 1.1rem;
				}
			}
		}
	}


	.textAvatarAddBlock {
		margin-bottom: 20px;
		text-align: center;
	}

	.customFileUpload,
	.profile__addButton,
	.profile__link,
	.profile__logoutButton {
		display: inline-block;
		width: 300px;
		height: 30px;
		border-radius: 5px;
		font-family: 'LetteraTrentadue', sans-serif;
		font-size: 16px;
		color: var(--color-main);
		background-color: var(--color-blue);
		border: none;
		transition: background-color 0.3s ease;
		cursor: pointer;
		margin-bottom: 10px;
		text-align: center;
		line-height: 30px;

		&:hover {
			background-color: var(--color-red);
		}

		@include mobile {
			width: 100%;
		}
	}

	.profile__logoutButton {
		width: 200px;
		height: 40px;
		border-radius: 10px;
		box-shadow: 0 0 50px 0 #00000033;
		margin: 20px auto 20px;
		font-size: 28px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: var(--color-blue);
		}

		@include mobile {
			width: 100px;
			height: 30px;
			font-size: 18px;
		}
	}
}
