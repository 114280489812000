// ConspectPage.module.scss
@import '../../../../styles/media';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.conspectPage {
	max-width: 1440px;
	padding: 110px 70px 0 70px;
	margin: 0 auto;
	text-align: left;

	@include tablet {
		padding: 110px 20px 0 20px;
	}

	@include mobile {
		padding: 110px 10px 0 10px;
	}

	@include mobile-small {
		padding: 110px 5px 0 5px;
	}

	h2 {
		margin-bottom: 2rem;
		text-align: center;

		@include tablet {
			font-size: 24px;
		}

		@include mobile {
			font-size: 20px;
		}

		@include mobile-small {
			font-size: 18px;
		}
	}

	.conspectsContainer {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		@include mobile {
			flex-direction: column;
		}
	}

	.conspectItem {
		flex: 1 1 calc(50% - 20px);
		box-sizing: border-box;
		margin-bottom: 2rem;

		@include mobile {
			flex: 1 1 100%;
		}

		h2 {
			font-size: 1.5rem;
			margin-bottom: 1rem;

			@include tablet {
				font-size: 1.1rem;
			}

			@include mobile {
				font-size: 1.1rem;
			}

			@include mobile-small {
				font-size: 1rem;
			}
		}

		.iframeContainer {
			width: 100%;
			height: 400px;
			margin-bottom: 1rem;

			iframe {
				width: 100%;
				height: 100%;
				border: none;
			}
		}

		.downloadButton {
			@include button-style;
			background-color: var(--color-blue);
			color: var(--color-white);
			margin: 0;
			font-size: 1.3rem;
			width: 100%;

			&:hover {
				background-color: var(--color-red);
			}

			@include tablet {
				font-size: 1.1rem;
				width: 100%;
			}

			@include mobile {
				font-size: 1.3rem;
				width: 100%;
			}

			@include mobile-small {
				font-size: 1.3rem;
				width: 100%;
			}
		}
	}

	.backButton {
		@include button-style;
		width: 400px;

		@include tablet {
			font-size: 1.3rem;
			width: 100%;
		}

		@include mobile {
			font-size: 1.3rem;
			width: 100%;
		}

		@include mobile-small {
			font-size: 1.3rem;
			width: 100%;
		}
	}
}
