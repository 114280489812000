// GalleriesList.module.scss
@import '../../../styles/media';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.galleries {
	max-width: 1440px;
	padding: 100px 0 0 0;
	margin: 0 auto;
	text-align: center;

	h1 {
		margin-bottom: 2rem;

		@include mobile {
			margin-bottom: 0;
			font-size: 26px;
		}

		@include mobile-small {
			margin-bottom: 0;
			font-size: 24px;
		}
	}

	&__container {
		width: 100%;
		padding: 2rem 2rem;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 2rem;
		justify-content: center;
		align-items: center;

		@include mobile {
			grid-template-columns: 1fr;
			padding: 1rem 1rem;
		}

		@include mobile-small {
			grid-template-columns: 1fr;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: var(--color-black);
		border-radius: 2px;
		background: var(--color-main);
		box-shadow: var(--box-shadow);
		padding: 1rem;
		text-decoration: none;

		&_title {
			font-size: 1.5rem;
			margin-bottom: 1rem;
		}

		&_image {
			position: relative;
			display: block;
			width: 300px;
			height: 300px;
			background: var(--color-main);
			cursor: pointer;

			img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}