// galleryPage.scss
@import '../../styles/media';
@import '../../styles/variables';
@import '../../styles/mixins';

.gallery {
	max-width: 1440px;
	padding: 110px 70px 0 70px;
	margin: 0 auto;
	text-align: center;

	@include tablet {
		padding: 110px 20px 0 20px;
	}

	@include mobile {
		padding: 110px 10px 0 10px;
	}

	@include mobile-small {
		padding: 110px 5px 0 5px;
	}

	h1 {
		margin-bottom: 2rem;

		@include tablet {
			text-align: center;
			font-size: 28px;
		}

		@include mobile {
			text-align: center;
			font-size: 26px;
		}

		@include mobile-small {
			text-align: center;
			font-size: 26px;
		}
	}

	&__description {
		margin-bottom: 2rem;
		text-align: left;

		@include tablet {
			margin-bottom: 1rem;
		}

		@include mobile {
			margin: 0 0.3rem 1rem 0.3rem;

			p {
				font-size: 18px;
			}
		}

		@include mobile-small {
			margin-bottom: 1rem;

			p {
				font-size: 18px;
			}
		}
	}

	&__container {
		width: 100%;
		padding: 1rem 2rem;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
		justify-content: center;
		align-items: center;

		@include mobile-small {
			grid-template-columns: 1fr;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		text-align: center;
		color: var(--color-black);
		border-radius: 5px;
		background: var(--color-main);
		box-shadow: var(--box-shadow);
		padding: 1rem;
		overflow: hidden;


		&_image {
			position: relative;
			display: block;
			width: 100%;
			padding-top: 70%;
			background: var(--color-main);
			cursor: pointer;

			@include tablet {
				padding-top: 100%;
			}

			@include mobile {
				padding-top: 100%;
			}

			@include mobile-small {
				padding-top: 100%;
			}

			img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.backButton {
		@include button-style;
		margin: 0 auto 20px;
		display: block;
		font-size: 1.4rem;
		width: 270px;


		@include tablet {
			font-size: 1.3rem;
			width: 100%;
		}

		@include mobile {
			font-size: 1.1rem;
			width: 100%;
		}

		@include mobile-small {
			font-size: 1.1rem;
			width: 100%;
		}
	}
}